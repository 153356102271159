import request from '../util/request'

export function detail(id) {
  return request({
    url: '/product/detail?productId=' + id,
    method: 'get'
  })
}

export function customizeConfig(params) {
  return request({
    url: '/product/customize/config',
    method: 'get',
    params: params
  })
}

export function productComments(id) {
  return request({
    url: '/product/comments?productId=' + id,
    method: 'get'
  })
}
